import { useMediaQuery } from "@react-hook/media-query";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { animateScroll as scroll } from "react-scroll";

export default function Contactbanner() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 100,
      smooth: "smooth",
    });
  };

  return (
    <BannerWrap>
      <Container fluid className="pt-5 pb-5">
        <Row>
          <Col className={` ${isMobile ? "" : "contain-margin"}`}>
            <Container className="shadow-box contain-margin pt-5 pb-5">
              <div>
                <h3 className={` ${isMobile ? "text1-mob" : "text1"}`}>
                  Are you looking for{" "}
                  <span style={{ color: "#2d53da" }}>
                    personalized pricing{" "}
                  </span>
                  for your organization?{" "}
                </h3>
                <p className="text2 pt-4">We are here to help you!</p>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className="theme-filldbtn my-4"
                  onClick={() => {
                    navigate("/contactus");
                    scrollToTop();
                  }}
                >
                  Contact Us
                </button>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </BannerWrap>
  );
}

export const BannerWrap = styled.div`
  .shadow-box {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    background-color: white;
    text-align: center;
    /* background: linear-gradient(10deg, #2d53da, white); */
    background: rgb(217, 215, 255);
    background: linear-gradient(
      45deg,
      rgba(217, 215, 255, 1) 0%,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  .text1 {
    font-size: 36px;
    font-weight: 700;
    color: black;
  }

  .text2 {
    font-size: 20px;
    font-weight: 400;
    color: #1f243b;
  }

  .text1-mob {
    font-size: 20px;
    font-weight: 700;
    color: #2d53da;
  }

  .custom-shape-divider-top-1713586688 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(90deg);
  }

  .custom-shape-divider-top-1713586688 svg {
    position: relative;
    display: block;
    width: calc(184% + 1.3px);
    height: 112px;
  }

  .custom-shape-divider-top-1713586688 .shape-fill {
    fill: #a1b2cd;
  }
`;
