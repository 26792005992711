import React, { useState } from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Spin, message } from "antd";
import axios from "axios";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logo from "../../../Assests/Frame 2147225291.svg";
import { baseURL } from "../../../Services/api";
import { RegisterWrap } from "../style";
import { jwtDecode } from "jwt-decode";

export const Login = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validateEmail = (value) => {
    const isValidEmail = /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      value
    );
    return isValidEmail;
  };

  const handleChangeEmail = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (newEmail.length === 0) {
      setEmailError("Email is required");
    } else if (!validateEmail(newEmail)) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };

  const handleChangePassword = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    if (newPassword.length === 0) {
      setPasswordError("Password is required");
    } else if (newPassword.length < 8) {
      setPasswordError("Password must be 8 characters at minimum");
    } else {
      setPasswordError("");
    }
  };

  const onsuccess = (response) => {
    const restoken = jwtDecode(response?.data?.authorizationToken);
    if (restoken?.signInTo?.tymeplusHrStatus) {
      window.location.href = `http://dev.app.system.tymeplushr.com/?token=${response.data.authorizationToken}`;
    } else if (restoken?.signInTo?.tymeplusPayStatus) {
      window.location.href = `https://www.pay.tymeplushr.com/?token=${response.data.authorizationToken}`;
    } else if (restoken?.signInTo?.tymeplusLmsStatus) {
      window.location.href = `https://www.dev.learn.tymeplushr.com/?token=${response.data.authorizationToken}`;
    }  else if (restoken?.signInTo?.tymeplusSupport) {
      window.location.href = `https://support.system.tymeplushr.com/?token=${response.data.authorizationToken}`;
    }else {
      message.error("something went wrong");
    }
  };

  const validate = (e) => {
    e.preventDefault();
    let hasError = false;

    if (email.length === 0) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (password.length === 0) {
      setPasswordError("Password is required");
      hasError = true;
    } else if (password.length < 8) {
      setPasswordError("Password must be 8 characters at minimum");
      hasError = true;
    } else {
      setPasswordError("");
    }

    if (!hasError) {
      onSubmit(e);
    }
  };

  const onSubmit = async (e) => {
    if (emailError === "" && passwordError === "") {
      setLoading(true);
      try {
        const response = await axios.post(`${baseURL}/user/login`, {
          email: email,
          password: password,
          signinto: {
            tymeplusPay: true,
            tymeplusHr: true,
          },
        });
        console.log("response-->", response);
        if (response.data.status) {
          onsuccess(response);
          setLoading(false);
        } else {
          messageApi.open({
            type: "error",
            content: response.data.message,
          });
        }
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <RegisterWrap>
      {contextHolder}

      <div className="min-h-screen bgimage">
        <div className="form-contain">
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="rounded-xl shadow-lg overflow-hidden contain">
                <Row>
                  <Col
                    className="text4 color4 d-none d-lg-block"
                    xs={12}
                    md={12}
                    lg={7}
                  >
                    <div className="text-start text-content">
                      <h1 className="pb-5">Welcome Back!</h1>{" "}
                      <p className="">"Let's Dive Into Efficiency Together"</p>
                    </div>
                  </Col>
                  <Col
                    className="pb-5 pt-4 d-flex flex-column bg-white mobileview-form"
                    xs={12}
                    md={12}
                    lg={5}
                  >
                    <Spin spinning={loading}>
                      <img
                        src={logo}
                        alt="logo"
                        width="150"
                        className="mx-auto pt-2 pb-3 cursor-pointer"
                        onClick={() => navigate("/")}
                      />

                      <h1 className="text-center text-bold text3 pb-3 color3">
                        Login
                      </h1>
                      <Form
                        className="px-4 mx-4 login-formalign"
                        onSubmit={(e) => validate(e)}
                      >
                        <Form.Group controlId="formBasicEmail" className="py-3">
                          <Form.Label className="text1 color2">
                            Username
                          </Form.Label>
                          <Form.Control
                            name="email"
                            type="email"
                            placeholder=""
                            className="placeholder-style"
                            onChange={handleChangeEmail}
                            value={email}
                          />

                          <span className="error-msg">{emailError}</span>
                        </Form.Group>
                        <Form.Group
                          controlId="formBasicPassword"
                          className="pb-2"
                        >
                          <Form.Label className="text1 color2">
                            Password
                          </Form.Label>

                          <InputGroup>
                            <Form.Control
                              value={password}
                              onChange={handleChangePassword}
                              name="password"
                              placeholder=""
                              type={showPassword ? "text" : "password"}
                            />
                            <InputGroup.Text
                              className="cursor-pointer"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <VisibilityOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                          <span className="error-msg">{passwordError}</span>
                        </Form.Group>
                        <p
                          className="color5 text2 pb-3 cursor-pointer"
                          onClick={() => navigate("/forgetpassword")}
                        >
                          Forgot Password?
                        </p>
                        <div className="text-center pt-4 mt-2 pb-2">
                          <button className="form-btn" type="submit">
                            Login
                          </button>
                        </div>

                        <div className="text-center pt-2 pb-2 text2">
                          <p>
                            New User?
                            <span
                              className="color5 cursor-pointer ps-1"
                              onClick={() => navigate("/register")}
                            >
                              Start a free trial
                            </span>
                          </p>
                        </div>
                      </Form>
                    </Spin>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </RegisterWrap>
  );
};
