import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaQuery } from "@react-hook/media-query";
import { Modal, Spin, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import circletick from "../../Assests/Circle_Check.svg";
import greentick from "../../Assests/Greentick.svg";
import popupimage from "../../Assests/Popupimage.svg";
import { baseURL } from "../../Services/api";
import FeedbackWrap from "./style";

export const Feedback = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const schema = yup.object().shape({
    fname: yup.string().required("This field is required."),
    lname: yup.string().required("This field is required."),
    emailid: yup
      .string()
      .email("Please Enter valid Email")
      .required("This field is required."),
    feedback: yup.string().required("This field is required."),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleOk = () => {
    setIsModalOpen(false);
    reset();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    reset();
  };

  const handleKeyDown = (event) => {
    const isNumericKey =
      (event.key >= "0" && event.key <= "9") ||
      [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
        "Tab",
      ].includes(event.key);
    const isCtrlKey = event.key === "Control" || event.ctrlKey || event.metaKey;
    if (!isNumericKey && !isCtrlKey) {
      event.preventDefault();
    }
  };
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    console.log(" data -->", data);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}/feedback-form/` + `${data.emailid}`,
          {
            firstname: data.fname,
            lastname: data.lname,
            phoneno: data.phoneno,
            feedback: data.feedback,
          }
        );

        if (response.data.status) {
          setIsModalOpen(true);
          setTimeout(() => {
            setIsModalOpen(false);
          }, 3000);
          setLoading(false);
          reset();
        } else {
          messageApi.open({
            type: "error",
            content: response.data.message,
          });
        }
        setLoading(false);
        reset();
      } catch (error) {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "Something went wrong",
        });
        reset();
      }
    } else {
      console.log("Form has validation errors:", errors);
      messageApi.open({
        type: "error",
        content: "Internal Server Error",
      });
      reset();
    }
  };

  return (
    <>
      <FeedbackWrap>
        <section className="feedback-section contain-margin">
          <Spin spinning={loading}>
            <Row>
              <Col lg={6} sm={12}>
                <div className="text-start py-4 ms-4 ps-2 pt-4 feedback-contsection">
                  <h3 className="color3 text1 pb-4">
                    We look forward to your feedback
                  </h3>
                  <p className="color2 text2">
                    Your feedback is essential in helping us understand what
                    we're doing well and where we can improve. We welcome your
                    thoughts, suggestions, and comments on how we can better
                    serve you. Together, we can build a stronger and more
                    responsive service tailored to your needs.
                  </p>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                {contextHolder}
                <Form onSubmit={handleSubmit(onSubmit)}>
                  {" "}
                  <Row className="pt-2 mt-3 feedback-form">
                    <Col xs={12} lg={6}>
                      <Form.Group
                        className="mb-3 text-start"
                        controlId="formFirstName"
                      >
                        <Form.Label className="label-custom">
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          {...register("fname")}
                          name="fname"
                          onKeyDown={(e) => {
                            const allowedChars = /^[a-zA-Z\s]+$/;
                            if (!allowedChars.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={15}
                        />
                        <span className="error-msg">
                          {errors.fname?.message}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group
                        className="mb-3 text-start"
                        controlId="formLastName"
                      >
                        <Form.Label className="label-custom">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          {...register("lname")}
                          name="lname"
                          onKeyDown={(e) => {
                            const allowedChars = /^[a-zA-Z\s]+$/;
                            if (!allowedChars.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={15}
                        />
                        <span className="error-msg">
                          {errors.lname?.message}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="feedback-form">
                    <Col xs={12} lg={6}>
                      <Form.Group
                        className="mb-3 text-start"
                        controlId="formPhone"
                      >
                        <Form.Label className="label-custom">
                          Phone (optional)
                        </Form.Label>
                        <Form.Control
                        {...register("phoneno")}
                          type="text"
                          placeholder=""
                          name="phoneno"
                          onKeyDown={(event) => handleKeyDown(event)}
                          onPaste={(event) => handlePaste(event)}
                          maxLength={10}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group
                        className="mb-3 text-start"
                        controlId="formEmailID"
                      >
                        <Form.Label className="label-custom">
                          Email ID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          {...register("emailid")}
                          name="emailid"
                        />
                        <span className="error-msg">
                          {errors.emailid?.message}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="feedback-form">
                    <Col>
                      <Form.Group
                        className="mb-3 text-start"
                        controlId="formFeedback"
                      >
                        <Form.Label className="label-custom">
                          Feedback
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          {...register("feedback")}
                          name="feedback"
                          style={{ resize: "none" }}
                        />
                        <span className="error-msg">
                          {errors.feedback?.message}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-start align-items-start sub-btn">
                        <button
                          type=""
                          className="theme-filldbtn mb-4 text-start"
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Spin>
        </section>
        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <FeedbackWrap>
            <Row>
              <Col className="bgimg" lg={12}>
                <img src={popupimage} alt="popup" />
                <div className="d-flex justify-content-center">
                  <img
                    src={isMobile ? circletick : greentick}
                    alt="tick"
                    className="successtick"
                  />
                </div>
              </Col>
              <Col className="content py-4 my-4 text-center" lg={12}>
                <p>Submitted Successfully!</p>
                <p>Thanks for your time and feedback</p>
              </Col>
            </Row>
          </FeedbackWrap>
        </Modal>
      </FeedbackWrap>
    </>
  );
};
