import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaQuery } from "@react-hook/media-query";
import { DatePicker, Modal, Spin, message } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import circletick from "../../Assests/Circle_Check.svg";
import contactmobile from "../../Assests/Contact.svg";
import greentick from "../../Assests/Greentick.svg";
import popupimage from "../../Assests/Popupimage.svg";
import contactperson from "../../Assests/Web.svg";
import { baseURL } from "../../Services/api";
import { CountryData } from "../datas/formdata";
import ContactWrap from "./style";
dayjs.extend(customParseFormat);

export const Contact = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    clearErrors,
  } = useForm();

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleKeyDown = (event) => {
    const isNumericKey =
      (event.key >= "0" && event.key <= "9") ||
      [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
        "Tab",
      ].includes(event.key);
    const isCtrlKey = event.key === "Control" || event.ctrlKey || event.metaKey;
    if (!isNumericKey && !isCtrlKey) {
      event.preventDefault();
    }
  };
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    if (!/^\d+$/.test(pastedData)) {
      event.preventDefault();
    }
  };

  const validateEmail = (value) => {
    const isValidEmail = /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      value
    );
    return isValidEmail;
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleClearDate = () => {
    setSelectedDate(null);
  };

  const onSubmit = async (data) => {
    console.log(" data -->", data);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}/contact-form/` + `${data.emailid}`,
          {
            firstname: data.fname,
            lastname: data.lname,
            phoneno: data.phoneNumber,
            companyname: data.companyName,
            role: data.role,
            noofemployees: data.noOfEmployees,
            availabledate: data.date,
            country: data.country,
          }
        );

        if (response.data.status) {
          setIsModalOpen(true);
          setTimeout(() => {
            setIsModalOpen(false);
          }, 3000);
          setLoading(false);
          reset();
          handleClearDate();
          setSelectedCountry("");
        } else {
          messageApi.open({
            type: "error",
            content: response.data.message,
          });
          setLoading(false);
          reset();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "Something went wrong",
        });
        reset();
      }
    } else {
      messageApi.open({
        type: "error",
        content: "Internal Server Error",
      });
      reset();
    }
  };

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (event) => {
    const selectedCountryName = event.target.value;
    const selectedCountryData = CountryData.find(
      (country) => country.name === selectedCountryName
    );

    if (selectedCountryData) {
      setSelectedCountry(selectedCountryData.dial_code);
    }
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  return (
    <>
      <ContactWrap>
        <Container fluid>
          <Spin spinning={loading}>
            <Row>
              <Col className="bg-color text-start" md={12} lg={6}>
                <Container className="py-4 my-4 pe-4 ms-2 contact-contain">
                  <h3 className="contact pb-3">Contact for Sale!</h3>
                  <p className="pb-3 contact-style">
                    Wondering about pricing and packages?{" "}
                  </p>
                  <p className="pb-3 text-content">
                    Contact us for pricing information tailored to your
                    organization's needs. Our sales team will provide
                    transparent pricing details and help you choose the right
                    plan for your budget and requirements.
                  </p>
                </Container>{" "}
                <Row>
                  <Col className="ms-4 image-col">
                    <div className="img-cont">
                      <img
                        src={isMobile ? contactmobile : contactperson}
                        alt="image"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={6}>
                {contextHolder}
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="pt-4 mt-4 px-4 mx-4 contact-form">
                    <Col xs={12} lg={6} className="mb-3">
                      <Form.Group
                        className="text-start"
                        controlId="formFirstName"
                      >
                        <Form.Label className="label-custom">
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=" "
                          {...register("fname", {
                            required: "This field is required",
                          })}
                          name="fname"
                          onKeyDown={(e) => {
                            const allowedChars = /^[a-zA-Z\s]+$/;
                            if (!allowedChars.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={15}
                        />
                      </Form.Group>
                      {errors.fname && (
                        <span className="error-msg">
                          {errors.fname.message}
                        </span>
                      )}
                    </Col>
                    <Col xs={12} lg={6} className="mb-3">
                      <Form.Group
                        className="text-start"
                        controlId="formLastName"
                      >
                        <Form.Label className="label-custom">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=" "
                          {...register("lname", {
                            required: "This field is required",
                          })}
                          name="lname"
                          onKeyDown={(e) => {
                            const allowedChars = /^[a-zA-Z\s]+$/;
                            if (!allowedChars.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={15}
                        />
                      </Form.Group>
                      {errors.lname && (
                        <span className="error-msg">
                          {errors.lname.message}
                        </span>
                      )}
                    </Col>
                  </Row>

                  <Row className="px-4 mx-4 contact-form">
                    <Col xs={12} lg={6} className="mb-3">
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label className="label-custom">
                          Select Country
                        </Form.Label>
                        <Form.Select
                          {...register("country", {
                            required: "This field is required",
                          })}
                          name="country"
                          onChange={(e) => {
                            handleCountryChange(e);
                            clearErrors("country");
                          }}
                          autoComplete="off"
                        >
                          <option value="">Select Country</option>
                          {CountryData.map((country, index) => (
                            <option key={index} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      {errors.country && (
                        <span className="error-msg">
                          This field is required
                        </span>
                      )}
                    </Col>

                    <Col xs={12} lg={6} className="mb-3">
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label className="label-custom">
                          Phone Number
                        </Form.Label>
                        <InputGroup>
                          {selectedCountry && (
                            <InputGroup.Text id="basic-addon1">
                              {" "}
                              {selectedCountry}
                            </InputGroup.Text>
                          )}
                          <Form.Control
                            {...register("phoneNumber", {
                              required: "This field is required",
                            })}
                            className="placeholder-style"
                            name="phoneNumber"
                            onKeyDown={(event) => handleKeyDown(event)}
                            onPaste={(event) => handlePaste(event)}
                            autoComplete="off"
                            maxLength={10}
                          />
                        </InputGroup>
                      </Form.Group>
                      {errors.phoneNumber && (
                        <span className="error-msg">
                          This field is required
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row className="px-4 mx-4 contact-form">
                    <Col lg={12} className="mb-3">
                      <Form.Group className="text-start" controlId="formEmail">
                        <Form.Label className="label-custom">
                          Work Mail
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder=""
                          name="emailid"
                          {...register("emailid", {
                            required: "This field is required",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                              message: "Please enter a valid email",
                            },
                          })}
                        />
                        <span className="error-msg">
                          {errors.emailid?.message}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-3">
                      <Form.Group
                        className="text-start"
                        controlId="formCompanyName"
                      >
                        <Form.Label className="label-custom">
                          Company Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          {...register("companyName", {
                            required: "This field is required",
                          })}
                          name="companyName"
                          maxLength={15}
                        />
                      </Form.Group>
                      {errors.companyName && (
                        <span className="error-msg">
                          This field is required
                        </span>
                      )}
                    </Col>
                    <Col lg={12} className="mb-3">
                      <Form.Group className="text-start" controlId="formRole">
                        <Form.Label className="label-custom">
                          Your Role
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=" "
                          {...register("role", {
                            required: "This field is required",
                          })}
                          name="role"
                          maxLength={15}
                        />
                      </Form.Group>
                      {errors.role && (
                        <span className="error-msg">
                          This field is required
                        </span>
                      )}
                    </Col>
                  </Row>

                  <Row className="px-4 mx-4 contact-form">
                    <Col xs={12} lg={6} className="mb-3">
                      <Form.Group
                        className="text-start"
                        controlId="formNumberOfEmployees"
                      >
                        <Form.Label className="label-custom">
                          No of Employees
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          onKeyDown={handleKeyDown}
                          {...register("noOfEmployees", {
                            required: "This field is required",
                          })}
                          name="noOfEmployees"
                          maxLength={15}
                        />
                      </Form.Group>
                      {errors.noOfEmployees && (
                        <span className="error-msg">
                          This field is required
                        </span>
                      )}
                    </Col>

                    <Col className="mb-3">
                      <Form.Group
                        className="text-start"
                        controlId="formDateAvailable"
                      >
                        <Form.Label className="label-custom">
                          Date Available
                        </Form.Label>
                        <br />
                        <Controller
                          name="date"
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field }) => (
                            <DatePicker {...field} format="DD/MM/YYYY" />
                          )}
                        />
                      </Form.Group>
                      {errors.date && (
                        <span className="error-msg">
                          Date Available is required
                        </span>
                      )}
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-center align-items-center">
                    <button className="theme-filldbtn my-4 ms-4">Submit</button>{" "}
                  </div>
                </Form>
              </Col>
            </Row>
          </Spin>
        </Container>
        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <ContactWrap>
            <Row>
              <Col className="bgimg" lg={12}>
                <img src={popupimage} />
                <div className="d-flex justify-content-center">
                  <img
                    src={isMobile ? circletick : greentick}
                    alt="tick"
                    className="successtick"
                  />
                </div>
              </Col>
              <Col className="content py-4 my-4 text-center" lg={12}>
                <p className="contact-para">Submitted Successfully!</p>
                <p className="contact-para">Our team will reach out soon</p>
              </Col>
            </Row>
          </ContactWrap>
        </Modal>
      </ContactWrap>
    </>
  );
};
