import styled from "styled-components";
import loginbg from "../../Assests/Signupbackground.svg";

export const RegisterWrap = styled.div`
  .bgimage {
    background-image: url(${loginbg});
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
    background-size: cover;
  }

  .align-start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%; /* Adjust this based on your layout */
  }

  .login-form {
    background-color: #ffffff;
  }

  .full-contain {
    border: 1px solid #2d53da;
  }

  .error-msg {
    color: #f2545b;
    font-size: 14px;
    font-family: "DM Sans", sans-serif !important;
  }

  .text1 {
    font-size: 14px;
    font-weight: 500;
  }

  .text2 {
    font-size: 16px;
    font-weight: 500;
  }

  .text3 {
    font-size: 28px;
    font-weight: 700;
  }

  .text4 {
    font-size: 48px;
    font-weight: 600;
  }

  .text5 {
    font-size: 20px;
    font-weight: 400;
  }

  .contain {
    border: 6px solid #ffffff;
  }

  .form-control {
    border: var(--bs-border-width) solid #95a2ae91;
    color: #616161 !important;
  }

  .placeholder-style::placeholder {
    color: #50595e;
    font-size: 16px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif !important;
  }

  .text-content {
    padding-top: 25%;
    padding-left: 15%;
    padding-right: 15%;
  }

  .loginpassword-icon {
  position: absolute;
  right: 55px;
  top: 60%;
  transform: translateY(-50%);
  cursor: pointer;
}

.placeholder-style {
  padding-right: 40px; /* Adjust the padding to prevent text from hiding under the icon */
}

.password-icon {
  position: absolute;
  right: 55px;
  top: 20%;
  transform: translateY(-60%);
  cursor: pointer;
}

.placeholder-style {
  padding-right: 40px; /* Adjust the padding to prevent text from hiding under the icon */
}

.confirmpassword-icon{
  position: absolute;
  right: 55px;
  top: 50%;
  transform: translateY(-70%);
  cursor: pointer;
}

.placeholder-style {
  padding-right: 40px;
}

  @media screen and (max-width: 768px) {


    .text4 {
      font-size: 27px;
      font-weight: 700;
    }

    .bgimage {
      padding-left: 0%;
      padding-right: 0%;
      padding-top: 0%;
    }

    .rounded-xl {
      border-radius: 0rem;
    }

    .theme-outlinedbtn {
      padding: 8px 30px !important;
    }
    .prev-btn {
      margin-right: 0rem !important;
      margin-inline-end: 0rem;
      margin-left: 0rem !important;
    }

    /* .login-formalign {
      padding-right: 1rem;
      padding-left: 1rem;
    } */

    .mobileview-form {
      height: 100vh;
    }

    .text5 {
      font-size: 18px;
      font-weight: 400;
      padding-right: 0rem !important;
      padding-left: 0rem !important;
    }

    .confirm-form {
      margin-right: 0rem !important;
      margin-left: 0rem !important;
    }

    .signup-form {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
    }

    .userdetail-form {
      padding-right: 0rem !important;
      padding-left: 0rem !important;
    }

    .theme-outlinedbtn {
      padding: 4px 15px !important;
    }
  }
`;
