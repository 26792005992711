import React, { useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BookaDemo } from "../Components/BookaDemo";
import { Contact } from "../Components/Contact";
import DataManagement from "../Components/DataManagement";
import Features from "../Components/Features";
import { Homepage } from "../Components/Homepage";
import { HowtoUse } from "../Components/HowtoUse";
import { Main } from "../Components/Layout/Main";
import { ForgetPassword } from "../Components/Pages/ForgetPassword";
import { Login } from "../Components/Pages/Login";
import { SignUp } from "../Components/Pages/Register";
import { Pricing } from "../Components/Pricing";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import Register from "../Components/Register";
import TermsAndConditions from "../Components/TermsAndConditions";
import { OwnerGuide } from "../Components/UserManual/ownerguide";
import { UserGuide } from "../Components/UserManual/userguide";

export const Routers = () => {
  const [color, setColor] = useState(false);
  const [scrollItem, setScrollItem] = useState("");

  const headerRef = useRef();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Main headerRef={headerRef} color={color} scrollItem={scrollItem} />
          }
        >
          <Route
            path="/"
            element={
              <Homepage
                setColor={setColor}
                color={color}
                setScrollItem={setScrollItem}
              />
            }
          />
          <Route path="/contactus" element={<Contact />} />
          <Route
            path="/features"
            element={<Features setScrollItem={setColor} />}
          />
          <Route
            path="/howtouse"
            element={<HowtoUse setScrollItem={setColor} />}
          />
          <Route
            path="/pricing"
            element={<Pricing setScrollItem={setScrollItem} />}
          />
          <Route path="/bookademo" element={<BookaDemo />} />
          <Route
            path="/termsandconditions"
            element={<TermsAndConditions headerRef={headerRef} />}
          />
          <Route
            path="/privacypolicy"
            element={<PrivacyPolicy headerRef={headerRef} />}
          />
          <Route
            path="/datamanagement"
            element={<DataManagement headerRef={headerRef} />}
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/owner-guide" element={<OwnerGuide/>}/>
        <Route path="user-guide" element={<UserGuide/>}/>
      </Routes>
    </BrowserRouter>
  );
};
