import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaLinkedin, FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import footerTymeplusLogo from "../../../Assests/footernewlogo.svg";
import { FooterWrap } from "./style";

export const Footers = () => {
  const handleLinkedInClick = () => {
    window.open("https://www.linkedin.com/company/waila-technology/", "_blank");
  };

  const handleTwitterClick = () => {
    window.open("https://x.com/wailatech", "_blank");
  };

  const openEmail = (id) => {
    window.open(`mailto:${id}`);
  };

  return (
    <FooterWrap>
      <section
        id="Footer contain-margin"
        style={{ backgroundColor: "#1f243b" }}
      >
        <Container fluid>
          <Row className="footer contain-margin py-3">
            <Col md={3}>
              <div className="pb-3">
                <Link
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  to="/"
                >
                  <img
                    src={footerTymeplusLogo}
                    width="150"
                    className="d-inline-block py-3 logo d-none d-lg-block"
                    alt="logo"
                  />
                </Link>
              </div>
            </Col>
            <Col md={6} className="">
              <div className="d-flex justify-content-center py-4 footer-link">
                <Link
                  to="/contactus"
                  className="px-4"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  Contact for Sale
                </Link>
                <Link
                  to="/"
                  onClick={(e) => {
                    setTimeout(() => {
                      const feedbackElement =
                        document.getElementById("Feedback");
                      if (feedbackElement) {
                        feedbackElement.scrollIntoView({
                          behavior: "smooth",
                        });
                      }
                    }, 100);
                  }}
                  className="px-4"
                >
                  Feedback
                </Link>
              </div>
            </Col>
            <Col md={3} className="fs-5">
              <div className="d-flex justify-content-end justify-content-center-mobile  py-4">
                <FaLinkedin
                  onClick={handleLinkedInClick}
                  className="socialmedia cursor-pointer mx-4"
                />
                <FaXTwitter
                  onClick={handleTwitterClick}
                  className="socialmedia cursor-pointer"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="contain-margin footer-text">
                <div className="pb-5 text1">
                  <div
                    onClick={() => openEmail("info@tymeplushr.com")}
                    className="cursor-pointer link-style"
                  >
                    Info@tymeplushr.com
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="d-none d-md-flex">
            <Col>
              <hr className="break" />
            </Col>
            <Col></Col>
            <Col>
              <hr className="break" />
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="contain-margin footer-text text-center">
                <div className="pb-5 text1 footer-link">
                  <Link
                    to="/termsandconditions"
                    className=" pe-4"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Terms & Conditions
                  </Link>
                </div>
                <div className="pb-5 text1 footer-link text-center">
                  <Link
                    to="/privacypolicy"
                    className=" pe-4"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Privacy policy
                  </Link>
                </div>
                <div className="pb-5 text1 footer-link text-center">
                  <Link
                    to="/datamanagement"
                    className=" pe-4"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Data management & Protection
                  </Link>
                </div>
                <div className="pb-5 text2">
                  <div>©2024 TymeplusHR. All rights reserved</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </FooterWrap>
  );
};
