import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

import { IconButton } from "@mui/material";
import styled from "styled-components";

export const RegisterWrap = styled.div`
  .success-content {
    font-size: 20px;
    text-align: center;
  }

  .theme-filldbtn {
    color: #ffffff;
    border: 2px solid #2d53da;
    background-color: #2d53da;
    border-radius: 10px;
    padding: 6px;
    width: 170px;
  }

  .tick-icon {
    font-size: 30px;
  }
`;

const ConfirmationModal = ({ show, setShow, token }) => {
  const navigate = useNavigate();

  const handleClose = () => navigate("/");

  const onsuccess = () => {
    window.location.href = `https://dev.app.system.tymeplushr.com/?token=${token}`;
  };

  return (
    <>
      <Modal backdrop="static" centered show={show} onHide={handleClose}>
        <div>
          <div className="d-flex justify-end">
            <IconButton onClick={handleClose}>
              {" "}
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <RegisterWrap>
          <div className=" d-flex justify-content-center">
            <CheckCircleIcon
              sx={{ fontSize: 60 }}
              style={{ color: "#08c108" }}
              fontSize="large"
            />
          </div>
          <div>
            <p className="success-content">
              Your free trial account has been activated!
            </p>
            <p className="success-content">
              Click OK to proceed to your dashboard
            </p>
          </div>
          <div className=" my-4 d-flex justify-content-center">
            <button
              className="theme-filldbtn"
              onClick={() => {
                onsuccess();
              }}
            >
              OK
            </button>
          </div>
        </RegisterWrap>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
