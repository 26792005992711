import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import image from "../../Assests/tymeplushrlogomobile.svg"
import playstore from "../../Assests/bxl_play-store.svg";
import iapple from "../../Assests/ic_sharp-apple.svg";

const Tymeplusmobile = () => {
  const handlePlayStoreClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.tymeplus.saas",
      "_blank"
    );
  };

  return (
    <>
      <MobileWrap>
        <Container>
          <Row className="pb-5 pt-5 mb-4">
            <Col lg={6} md={12} xs={12}>
              <div className="mobile-img">
                {" "}
                <img src={image} width="70%" />
              </div>
            </Col>
            <Col lg={6} md={12} xs={12}>
              <div className=" py-4 my-4 text-start mobile-contain">
                <h3 className="color3 text1 pt-4">TymeplusHR</h3>
                <p className="color2 text2 py-4">
                  With the mobile app, you can access all your organizational
                  management tools right from your smartphone. Whether you're in
                  the office, on the go, or working remotely, stay connected and
                  productive wherever you are.
                </p>
                <h2 className="color3 text3 mt-4">Download The App Now</h2>
                <div className="py-4 d-flex btn-download">
                  {/* <button className="mobile-filldbtn d-flex me-2">
                    <img src={iapple} /> Apple Store
                  </button>{" "} */}
                  <button
                    className="mobile-filldbtn d-flex me-2"
                    onClick={handlePlayStoreClick}
                  >
                    <img src={playstore} /> Play Store
                  </button>{" "}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </MobileWrap>
    </>
  );
};

export default Tymeplusmobile;

export const MobileWrap = styled.div`
  .text1 {
    font-size: 40px;
    font-weight: 700;
  }

  .mobile-img {
    display: flex !important;
    justify-content: center !important;
  }

  .text2 {
    font-size: 16px;
    font-weight: 400;
    padding-right: 22%;
  }

  .text3 {
    font-size: 20px;
    font-weight: 700;
  }

  .mobile-filldbtn {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    border: 2px solid #1f243b;
    background-color: #1f243b;
    border-radius: 10px;
    padding: 8px 24px;
  }

  @media screen and (max-width: 768px) {
    .mobile-img {
      display: flex !important;
      justify-content: center !important;
    }
    .btn-download {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
      text-align: center;
      justify-content: center;
    }

    .mobile-filldbtn {
      padding: 8px 14px !important;
    }

    .mobile-contain {
      padding-left: 0px !important;
      padding-right: 0px !important;
      text-align: center !important;
    }

    .text1 {
      font-size: 20px;
      font-weight: 700;
    }

    .text2 {
      font-size: 16px;
      font-weight: 400;
      padding-right: 5% !important;
      padding-left: 5% !important;
    }
  }
`;
