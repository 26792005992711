import { useMediaQuery } from "@react-hook/media-query";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled, { keyframes } from "styled-components";
import PricingWrap from "./style";

const steps = [
  {
    title: "Standard",
    users: `Upto 100 Users`,
    price: `$10`,
    month: `per user, per month`,
    button: `Get Started`,
    redirect: `/register`,
  },
  {
    title: "Premium",
    users: `Upto 200 Users`,
    price: `$14`,
    month: `per user, per month`,
    button: `Get Started`,
    redirect: `/register`,
  },
  {
    title: "Enterprise",
    users: `Over 200 Users`,
    price: `Talk to Us!`,
    month: `Will discuss`,
    button: `Contact Us`,
    redirect: `/contactus`,
  },
];

const randomInRange = (min, max) => {
  return Math.random() * (max - min) + min;
};
// Define keyframe animation for continuous random movement
const moveContinuousRandom = () => keyframes`
  0% {
    transform: translate(${randomInRange(-100, 400)}%, ${randomInRange(
  -100,
  400
)}%);
  }
  100% {
    transform: translate(${randomInRange(-100, 400)}%, ${randomInRange(
  -100,
  400
)}%);
  }`;

const GlowingBallsContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0d1a;
  z-index: -1;
  overflow: hidden;
`;

const GlowingBall = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 1;
  background-color: #0000ff;
  filter: blur(80px);
  animation: ${() => moveContinuousRandom()} 5s linear infinite
    alternate-reverse;
`;

export const Pricing = ({ setScrollItem }) => {
  const navigate = useNavigate();
  const pricingRef = useRef(null);
  const [isPricingVisible, setIsPricingVisible] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsPricingVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          setScrollItem("pricing");
        } else {
          setScrollItem("");
        }
      },
      { threshold: 0.5 }
    );

    if (pricingRef.current) {
      observer.observe(pricingRef.current);
    }

    return () => {
      if (pricingRef.current) {
        observer.unobserve(pricingRef.current);
      }
    };
  }, []);

  const settings = {
    className: isMobile ? "center" : "",
    centerMode: isMobile ? true : false,
    centerPadding: isMobile ? "50px" : "",
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveSlideIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <PricingWrap>
        <GlowingBallsContainer>
          <GlowingBall />
          <GlowingBall style={{ top: "50%", left: "50%" }} />
          <GlowingBall style={{ top: "30%", right: "10%" }} />
          <GlowingBall style={{ top: "50%", right: "30%" }} />
        </GlowingBallsContainer>

        <section className="mb-4 pb-5 pricing">
          <Container
            fluid
            style={{
              color: "#fff",
              border: "none",
            }}
            className="mb-4 pb-4"
            ref={pricingRef}
          >
            <Row>
              <Col>
                <h1 className="py-5 theme-heading color4 my-4 text-center">
                  Find the Best Pricing that Suits You!
                </h1>
                <div className="my-4 py-5 pricing-contain text-center">
                  <Slider {...settings}>
                    {steps.map((step, idx) => (
                      <div key={idx}>
                        <Card
                          className={`pricing-card ${
                            activeSlideIndex === idx
                              ? "active-slide"
                              : "inactive-slide"
                          }`}
                        >
                          <Card.Body className="py-4">
                            <Card.Title className="color5 card-title pt-4 pb-2">
                              {step.title}
                            </Card.Title>
                            <Card.Subtitle className="color2 mb-4 text-muted card-sub">
                              {step.users}
                            </Card.Subtitle>
                            <Card.Text className="color1 card-text pt-4 mt-4 mb-2">
                              {step.price}
                            </Card.Text>
                            <Card.Subtitle className="color2 mb-2 text-muted card-month">
                              {step.month}
                            </Card.Subtitle>
                            <button
                              className="theme-outlinedbtn my-4 mt-3"
                              onClick={() => {
                                navigate(step.redirect);
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                              }}
                            >
                              {step.button}
                            </button>{" "}
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </PricingWrap>
    </>
  );
};

export default Pricing;
