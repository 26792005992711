import CloseIcon from "@mui/icons-material/Close";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { useMediaQuery } from "@react-hook/media-query";
import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";
import changelogo from "../../../Assests/Frame 2147225287 new.svg";
import Headerlogo from "../../../Assests/Heedernewlogo.svg";
import HeaderWrap from "./style";
import { animateScroll as scroll } from "react-scroll";


export const Headers = ({ color, scrollItem, headerRef }) => {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const isMobile = useMediaQuery("(max-width: 1199px)");
  const isNotMobile = useMediaQuery("(min-width: 1200px)");

  const [headerStyle, setHeaderStyle] = useState({
    position: "sticky",
    top: 0,
    zIndex: 999,
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxShadow:
      "rgb(240 235 235 / 20%) 0px 4px 8px 0px, rgb(227 223 223 / 19%) 0px 2px 10px 0px",
    cursor: "pointer",
    paddingLeft: "8%",
    paddingRight: "10%",
    transition: "background-color 0.1s ease",
  });

 

  // Function to handle menu item selection

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    setShowOffcanvas(false); // Close offcanvas when a menu item is clicked
  };

  // Function to toggle offcanvas visibility
  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  // Function to close offcanvas when close icon is clicked
  const closeOffcanvas = () => {
    setShowOffcanvas(false);
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 100,
      smooth: "smooth",
    });
  };

  return (
    <>
      {["xl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          style={{
            ...headerStyle,
            backgroundColor: color ? "#1F243B" : headerStyle.backgroundColor,
            boxShadow: color ? "none" : headerStyle.boxShadow,
          }}
          id="header"
          className="header-navbar"
          ref={headerRef}
        >
          <Link
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            to="/"
          >
            <img
              src={color ? changelogo : Headerlogo}
              width="100"
              alt="logo"
              className="header-logo"
            />
          </Link>
          <div>
          <button
            onClick={handleLoginClick}
            className="login-button  d-xl-none px-2 px-md-4"
          >
           Login
          </button>
          <Button
            variant="link"
            onClick={toggleOffcanvas}
            className="navbar-toggler"
          >
            <DehazeIcon style={{ color: color ? "#fff" : "" }} />
          </Button>
          </div>
          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={() => setShowOffcanvas(false)}
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <HeaderWrap>
              <Offcanvas.Header className="custom-offcanvas-header">
                <div className="close-btn" onClick={closeOffcanvas}>
                  <CloseIcon />
                </div>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                ></Offcanvas.Title>
              </Offcanvas.Header>
            </HeaderWrap>

            <Offcanvas.Body className="offcanva-text">
              <Container>
                <HeaderWrap>
                  <Nav className="color1 menus-contain">
                    <div
                      className={`${isNotMobile ? "Navbar-contain" : ""}`}
                      style={{ width: "100%" }}
                    >
                      <Nav.Link
                        href="/"
                        style={{
                          color:
                            selectedMenuItem === "Home" ? "#6385FF" : "#212529",
                        }}
                        className="menus d-block d-xl-none"
                        onClick={() => handleMenuItemClick("Home")}
                      >
                        Home
                      </Nav.Link>
                      <Nav.Link
                        href="/#Features"
                        style={{
                          color:
                            color && selectedMenuItem === "Features"
                              ? "#6385FF"
                              : scrollItem === "Features"
                              ? "#6385FF"
                              : "#1F243B",
                        }}
                        onClick={() => handleMenuItemClick("Features")}
                        className="menus"
                      >
                        Features
                      </Nav.Link>
                      <Nav.Link
                        href="/#howtouse"
                        style={{
                          color: color
                            ? isMobile
                              ? "#212529"
                              : "#FFFFFF"
                            : "" ||
                              selectedMenuItem === "howtouse" ||
                              scrollItem === "howtouse"
                            ? "#6385FF"
                            : "#1F243B",
                        }}
                        onClick={() => handleMenuItemClick("howtouse")}
                        className="menus"
                      >
                        How to Use
                      </Nav.Link>
                      {/* <Nav.Link
                        href="/#Pricing"
                        style={{
                          color: color
                            ? isMobile
                              ? "#212529"
                              : "#FFFFFF"
                            : "" ||
                              selectedMenuItem === "pricing" ||
                              scrollItem === "pricing"
                            ? "#6385FF"
                            : "#1F243B",
                        }}
                        onClick={() => handleMenuItemClick("pricing")}
                        className="menus"
                      >
                        Pricing
                      </Nav.Link> */}
                    </div>
                    <div
                      className={`${
                        isNotMobile ? "login-hedaerbtn" : "login-mobilebtn"
                      }`}
                    >
                      <Nav>
                        <Nav.Link
                          href=""
                          className="menus me-3"
                          onClick={handleLoginClick}
                          style={{
                            color: color ? "white" : "#1F243B",
                          }}
                        >
                          Login
                        </Nav.Link>
                      </Nav>
                      {isNotMobile ? (
                        <div
                          className={`${
                            isNotMobile ? "login-btnalign" : "d-none"
                          }`}
                        >
                          <Button
                            variant="outline-secondary"
                            className="login-btn  p-1"
                            onClick={() => {
                              navigate("/register");
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                            style={{
                              color: color
                                ? isMobile
                                  ? "#212529"
                                  : "#FFFFFF"
                                : "#1F243B",
                            }}
                          >
                            Start a free trial
                          </Button>
                          <Button
                            className={` ${
                              color
                                ? isMobile
                                  ? "bg-btncolor mx-4"
                                  : "btnsignup-color mx-4"
                                : "btn-color mx-4"
                            }`}
                            onClick={() => {
                              navigate("/bookademo");
                              setShowOffcanvas(false);
                            }}
                          >
                            Book a Demo
                          </Button>
                        </div>
                      ) : (
                        <div className="login-mobilebtnalign">
                          <div>
                            <Button
                              variant="outline-secondary"
                              className="login-btn  p-1"
                              onClick={() => {
                                navigate("/register");
                                scrollToTop()
                              }}
                              style={{
                                color: color
                                  ? isMobile
                                    ? "#212529"
                                    : "#FFFFFF"
                                  : "#1F243B",
                              }}
                            >
                              Start a free trial
                            </Button>
                          </div>
                          <div>
                            <Button
                              className={` ${
                                color
                                  ? isMobile
                                    ? "bg-btncolor mx-2"
                                    : "btnsignup-color"
                                  : "btn-color my-4"
                              }`}
                              onClick={() => {
                                navigate("/bookademo");
                                setShowOffcanvas(false);
                                scrollToTop()
                              }}
                            >
                              Book a Demo
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Nav>
                </HeaderWrap>
              </Container>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      ))}
    </>
  );
};
